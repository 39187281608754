<template>
    <div class="wrapper_main">
        <main class="content">
            <div class="content__header sub-page">
                <div class="content__header-left">
                    <button type="button" class="btn btn-w-shadow btn-sm btn-back">
                        <inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)" />
                    </button>
                    <div class="heading-wrapper flex items-center ml-20">
                        <h1 class="heading-page-h1">123456789</h1>
                    </div>
                </div>
                <div class="content__header-right">
                    <button type="button" class="btn btn-w-shadow btn-standart-2 red-color">
                        <span>Cancel a Dispatch</span>
                    </button>
                </div>
            </div>
            <div class="tabs-wrapper">
                <ul class="tabs">
                    <li class="tabs__item">
                        <a data-tab="info" class="nav-tab" @click="switchTab('info')" :class="isActiveTab('info')"> Basic Info </a>
                    </li>
                    <li class="tabs__item">
                        <a data-tab="companies" class="nav-tab" @click="switchTab('companies')" :class="isActiveTab('companies')"> Select Companies </a>
                    </li>
                    <li class="tabs__item">
                        <a data-tab="products" class="nav-tab" @click="switchTab('products')" :class="isActiveTab('products')"> Products </a>
                    </li>
                    <li class="tabs__item">
                        <a data-tab="conditions" class="nav-tab" @click="switchTab('conditions')" :class="isActiveTab('conditions')"> Contract Conditions </a>
                    </li>
                    <li class="tabs__item">
                        <a data-tab="code-settings" class="nav-tab" @click="switchTab('code-settings')" :class="isActiveTab('code-settings')"> QR Code Settings </a>
                    </li>
                </ul>
            </div>
            <div class="content__body">
                <div class="tab-content">
                    <div id="info" class="tab-content__item" :class="isActiveTab('info')">
                        <div class="wizard-item">
                            <div class="items-group cards">
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">Dispatch ID</label>
                                        <input type="text" class="input" placeholder="123456789" />
                                    </div>
                                </div>
                            </div>
                            <hr class="line-hr my-25" />
                        </div>
                        <div class="headline-wrapper flex items-center">
                            <div class="headline headline--sm flex-auto">
                                <h2 class="headline__title">Dispatch Companies</h2>
                                <p class="headline__text">List of companies attached to despatch</p>
                            </div>
                        </div>
                        <ul class="editable-list">
                            <li class="editable-list__item">
                                <div class="data-row">
                                    <ul class="data-row__list">
                                        <li class="data-row__item">
                                            <span class="data-row__icon">
                                                <inline-svg :src="require(`@/assets/img/File-Pdf.svg`)" />
                                            </span>
                                            Custom Document Name1.pdf
                                        </li>
                                    </ul>
                                    <div class="data-row__controls">
                                        <button class="btn btn-icon red-color">
                                            <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <button type="button" class="btn btn-w-shadow btn-standart-2">
                            <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                            <span>Add Document</span>
                        </button>
                    </div>
                    <div id="companies" class="tab-content__item" :class="isActiveTab('companies')">
                        <div class="addition">
                            <div class="headline-wrapper flex items-center">
                                <div class="headline headline--sm flex-auto">
                                    <h2 class="headline__title">Select Companies</h2>
                                    <p class="headline__text">Add various companies to your dispatch</p>
                                </div>
                            </div>
                            <ul class="editable-list">
                                <li class="editable-list__item">
                                    <div class="data-row">
                                        <ul class="data-row__list">
                                            <li class="data-row__item">Company Name 1</li>
                                            <li class="data-row__item">
                                                <span class="table-user flex items-center"><img src="img/profile-photo.png" alt="" /> Esther Howard</span>
                                            </li>
                                        </ul>
                                        <div class="data-row__controls">
                                            <button class="btn btn-icon red-color">
                                                <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <button type="button" class="btn btn-w-shadow btn-standart-2">
                                <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                                <span>Add Document</span>
                            </button>
                        </div>
                    </div>
                    <div id="products" class="tab-content__item" :class="isActiveTab('products')">
                        <div class="addition">
                            <div class="headline-wrapper flex items-center">
                                <div class="headline headline--sm flex-auto">
                                    <h2 class="headline__title">Dispatch Products</h2>
                                    <p class="headline__text">List of batches and products attached to dispatch</p>
                                </div>
                                <div class="buttons-group add-buttons">
                                    <button type="button" class="btn btn-w-shadow btn-standart-2">
                                        <inline-svg :src="require(`@/assets/img/ico-qr-code.svg`)" />
                                        <span>QR Code</span>
                                    </button>
                                    <button type="button" class="btn btn-w-shadow btn-standart-2" data-target="qr-code-settings">
                                        <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                                        <span>QR Code Settings</span>
                                    </button>
                                    <button type="button" class="btn btn-w-shadow btn-standart-2">
                                        <inline-svg :src="require(`@/assets/img/document-file-table.svg`)" />
                                        <span>CSV Example</span>
                                    </button>
                                    <button type="button" class="btn btn-w-shadow btn-standart-2">
                                        <inline-svg :src="require(`@/assets/img/upload-arrow.svg`)" />
                                        <span>Upload CSV</span>
                                    </button>
                                    <button type="button" class="btn btn-w-shadow btn-standart-2">
                                        <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                                        <span>Add Batch</span>
                                    </button>
                                </div>
                            </div>
                            <ul class="editable-list">
                                <li class="editable-list__item">
                                    <div class="data-row --inner">
                                        <div class="data-row__spoiler spoiler flex-auto --style-2">
                                            <div class="spoiler__button flex items-center">
                                                <inline-svg :src="require(`@/assets/img/Arrow-down.svg`)" />
                                                <div class="spoiler__title flex-auto"><span>Batch:</span>123456789</div>
                                                <div class="buttons-group add-buttons">
                                                    <button type="button" class="btn btn-transp" data-target="qr-code">
                                                        <inline-svg :src="require(`@/assets/img/ico-qr-code.svg`)" />
                                                        <span>QR Code</span>
                                                    </button>
                                                    <button type="button" class="btn btn-transp" data-target="qr-code-settings">
                                                        <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                                                        <span>QR Code Settings</span>
                                                    </button>
                                                    <button type="button" class="btn btn-transp">
                                                        <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                                                        <span>Add Products</span>
                                                    </button>
                                                    <button type="button" class="btn btn-transp red-color">
                                                        <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                                        <span>Delete Batch</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="spoiler__body flex flex-col active">
                                                <div class="table-wrapper">
                                                    <table class="table style-2">
                                                        <thead>
                                                            <tr>
                                                                <th style="width: 60%">
                                                                    <label class="module__check">
                                                                        <input type="checkbox" name="privacy_policy" />
                                                                        <span class="check"></span>
                                                                        <span class="text">Product Name</span>
                                                                    </label>
                                                                </th>
                                                                <th>Invoice Number</th>
                                                                <th>PO Number</th>
                                                                <th colspan="2">RFID Number</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <label class="module__check">
                                                                        <input type="checkbox" name="privacy_policy" />
                                                                        <span class="check"></span>
                                                                        <span class="text">Barolo Collaretto 2009</span>
                                                                    </label>
                                                                </td>
                                                                <td>12345</td>
                                                                <td>PO-12345</td>
                                                                <td>E0040100019D0CA1</td>
                                                                <td>
                                                                    <button class="btn btn-icon red-color">
                                                                        <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="spoiler__body-actions flex">
                                                    <div class="buttons-group add-buttons flex-auto">
                                                        <button type="button" class="btn btn-transp" data-target="qr-code">
                                                            <inline-svg :src="require(`@/assets/img/ico-qr-code.svg`)" />
                                                            <span>QR Code</span>
                                                        </button>
                                                        <button type="button" class="btn btn-transp">
                                                            <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                                                            <span>QR Code Settings</span>
                                                        </button>
                                                        <button type="button" class="btn btn-transp">
                                                            <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                                                            <span>Add Products</span>
                                                        </button>
                                                    </div>
                                                    <button type="button" class="btn btn-transp red-color">
                                                        <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                                        <span>Delete Batch</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div class="buttons-group add-buttons">
                                <button type="button" class="btn btn-w-shadow btn-standart-2">
                                    <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                                    <span>Add Batch</span>
                                </button>
                                <button type="button" class="btn btn-w-shadow btn-standart-2 ml-auto">
                                    <inline-svg :src="require(`@/assets/img/ico-qr-code.svg`)" />
                                    <span>QR Code</span>
                                </button>
                                <button type="button" class="btn btn-w-shadow btn-standart-2" data-target="qr-code-settings">
                                    <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                                    <span>QR Code Settings</span>
                                </button>
                                <button type="button" class="btn btn-w-shadow btn-standart-2">
                                    <inline-svg :src="require(`@/assets/img/document-file-table.svg`)" />
                                    <span>CSV Example</span>
                                </button>
                                <button type="button" class="btn btn-w-shadow btn-standart-2">
                                    <inline-svg :src="require(`@/assets/img/upload-arrow.svg`)" />
                                    <span>Upload CSV</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="conditions" class="tab-content__item" :class="isActiveTab('conditions')">
                        <div class="addition">
                            <div class="headline-wrapper flex items-center">
                                <div class="headline headline--sm flex-auto">
                                    <h2 class="headline__title">Add Conditions</h2>
                                    <p class="headline__text">Add conditions to your contract dispatch</p>
                                </div>
                                <div class="buttons-group add-buttons">
                                    <button type="button" class="btn btn-w-shadow btn-standart-2" data-target="add-smart-condition">
                                        <span>Add Smart Condition</span>
                                    </button>
                                    <button type="button" class="btn btn-w-shadow btn-standart-2" data-target="add-business-condition">
                                        <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                                        <span>Add Business Condition</span>
                                    </button>
                                </div>
                            </div>
                            <ul class="editable-list">
                                <li class="editable-list__item">
                                    <div class="data-row">
                                        <ul class="data-row__list">
                                            <li class="data-row__item">
                                                <span class="data-row__icon">
                                                    <inline-svg :src="require(`@/assets/img/ico-signal.svg`)" />
                                                </span>
                                                Smart Condition
                                            </li>
                                            <li class="data-row__item">
                                                <span class="data-row__span">Batch: </span>
                                                123456789
                                            </li>
                                            <li class="data-row__item">
                                                <span class="data-row__span">Device: </span>
                                                Thermometer 1
                                            </li>
                                        </ul>
                                        <div class="data-row__controls">
                                            <button type="button" class="btn btn-icon">
                                                <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                                            </button>
                                            <button type="button" class="btn btn-icon red-color">
                                                <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                            </button>
                                        </div>
                                    </div>
                                </li>
                                <li class="editable-list__item">
                                    <div class="data-row">
                                        <ul class="data-row__list">
                                            <li class="data-row__item">
                                                <span class="data-row__icon">
                                                    <inline-svg :src="require(`@/assets/img/suitcase-portfolio.svg`)" />
                                                </span>
                                                Smart Condition
                                            </li>
                                            <li class="data-row__item">
                                                <span class="data-row__span">Batch: </span>
                                                123456789
                                            </li>
                                        </ul>
                                        <div class="data-row__controls">
                                            <button type="button" class="btn btn-icon">
                                                <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                                            </button>
                                            <button type="button" class="btn btn-icon red-color">
                                                <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div class="buttons-group add-buttons">
                                <button type="button" class="btn btn-w-shadow btn-standart-2" data-target="add-smart-condition">
                                    <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                                    <span>Add Smart Condition</span>
                                </button>
                                <button type="button" class="btn btn-w-shadow btn-standart-2" data-target="add-business-condition">
                                    <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                                    <span>Add Business Condition</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="code-settings" class="tab-content__item" :class="isActiveTab('code-settings')">
                        <div class="wizard-item footer-fixed">
                            <div class="headline headline--sm mt-15">
                                <h2 class="headline__title">Company Data</h2>
                                <p class="headline__text">Select info that you want to show in QR Code</p>
                            </div>
                            <div class="cards flex">
                                <div class="cards__col-4 cards__col-md-6 mt-15 flex flex-col">
                                    <label class="module__check">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">Location</span>
                                    </label>
                                    <label class="module__check mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">Documents</span>
                                    </label>
                                    <label class="module__check mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">Vineyard area</span>
                                    </label>
                                    <label class="module__check mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">Work in the garden</span>
                                    </label>
                                    <label class="module__check mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">Stocking density</span>
                                    </label>
                                </div>
                                <div class="cards__col-4 cards__col-md-6 mt-15 flex flex-col">
                                    <label class="module__check">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">History of the Company</span>
                                    </label>
                                    <label class="module__check mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">Year of Foundation</span>
                                    </label>
                                    <label class="module__check mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">Soils</span>
                                    </label>
                                    <label class="module__check mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">Cellar work</span>
                                    </label>
                                    <label class="module__check mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">Certification</span>
                                    </label>
                                </div>
                                <div class="cards__col-4 cards__col-md-6 mt-15 flex flex-col">
                                    <label class="module__check">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">Media</span>
                                    </label>
                                    <label class="module__check mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">Bottle production / year</span>
                                    </label>
                                    <label class="module__check mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">Age vine</span>
                                    </label>
                                    <label class="module__check mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">Productivity</span>
                                    </label>
                                    <label class="module__check mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">Philosophy</span>
                                    </label>
                                </div>
                            </div>
                            <div class="cards items-group border-top mt-15">
                                <div class="cards__col">
                                    <div class="headline-wrapper flex items-center">
                                        <div class="headline headline--sm flex-auto mt-15">
                                            <h2 class="headline__title">Assign Devices</h2>
                                            <p class="headline__text">Here you can find devices assigned to your prouct</p>
                                        </div>
                                        <button type="button" class="btn btn-w-shadow btn-standart-2 mt-15">
                                            <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                                            <span>Add Devices</span>
                                        </button>
                                    </div>
                                    <ul class="editable-list">
                                        <li class="editable-list__item">
                                            <div class="data-row flex-col has-spoiler">
                                                <div class="data-row__head flex w-full">
                                                    <ul class="data-row__list">
                                                        <li class="data-row__item">Thermometer Name 1</li>
                                                        <li class="data-row__item">
                                                            <span class="data-row__icon">
                                                                <inline-svg :src="require(`@/assets/img/Temperature.svg`)" />
                                                            </span>
                                                            Temperature
                                                        </li>
                                                        <li class="data-row__item">Italy, Rome</li>
                                                    </ul>
                                                    <div class="data-row__controls">
                                                        <button type="button" class="btn btn-icon red-color">
                                                            <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="data-row__spoiler spoiler">
                                                    <div class="spoiler__button flex items-center">
                                                        <span class="flex-auto">Device Info</span>
                                                        <inline-svg :src="require(`@/assets/img/Arrow-down.svg`)" />
                                                    </div>
                                                    <div class="spoiler__body flex flex-col active">
                                                        <label class="module__check">
                                                            <input type="checkbox" name="privacy_policy" />
                                                            <span class="check"></span>
                                                            <span class="text">Temperature at the day of product creation</span>
                                                        </label>
                                                        <label class="module__check mt-15">
                                                            <input type="checkbox" name="privacy_policy" />
                                                            <span class="check"></span>
                                                            <span class="text">Temperature during the day/week/month of the product creation</span>
                                                        </label>
                                                        <label class="module__check mt-15">
                                                            <input type="checkbox" name="privacy_policy" />
                                                            <span class="check"></span>
                                                            <span class="text">Temperature during the smart condition in the dispatch</span>
                                                        </label>
                                                        <label class="module__check mt-15">
                                                            <input type="checkbox" name="privacy_policy" checked="" />
                                                            <span class="check"></span>
                                                            <span class="text">Max/min value of the temperature during a time range</span>
                                                        </label>
                                                        <div class="cards flex" style="max-width: 630px">
                                                            <div class="cards__col-6 mt-15">
                                                                <div class="buttons-group">
                                                                    <DatePicker></DatePicker>

                                                                    <div class="categories time-piker dropdown-wrapper">
                                                                        <div id="datetimePickerf9d29515-224e-4593-8a58-aacd5c250837">
                                                                            <div class="datetimepicker-dummy is-primary">
                                                                                <div class="datetimepicker-dummy-wrapper">
                                                                                    <input placeholder="" readonly="readonly" class="datetimepicker-dummy-input" type="text" />

                                                                                    <input type="text" class="time is-hidden" value="00:00" />
                                                                                </div>
                                                                                <button class="datetimepicker-clear-button" type="button">＋</button>
                                                                            </div>
                                                                            <div class="datetimepicker-wrapper">
                                                                                <div class="modal-background is-hidden"></div>
                                                                                <div class="datetimepicker is-primary is-datetimepicker-default" style="position: absolute">
                                                                                    <div class="datetimepicker-container">
                                                                                        <div class="datetimepicker-header is-hidden">
                                                                                            <div class="datetimepicker-selection-details">
                                                                                                <div class="datetimepicker-selection-from is-hidden"></div>
                                                                                                <div class="datetimepicker-selection-start is-centered">
                                                                                                    <div class="datetimepicker-selection-wrapper">
                                                                                                        <div class="datetimepicker-selection-day">--</div>
                                                                                                        <div class="datetimepicker-selection-date">
                                                                                                            <div class="datetimepicker-selection-month"></div>
                                                                                                            <div class="datetimepicker-selection-weekday is-hidden"></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="datetimepicker-selection-time">
                                                                                                        <div class="datetimepicker-selection-time-icon">
                                                                                                            <figure class="image is-16x16">
                                                                                                                <svg version="1.1" x="0px" y="0px" viewBox="0 0 60 60" xml:space="preserve">
                                                                                                                    <g>
                                                                                                                        <path
                                                                                                                            fill="currentcolor"
                                                                                                                            d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z"
                                                                                                                        ></path>
                                                                                                                        <path
                                                                                                                            fill="currentcolor"
                                                                                                                            d="M30,6c-0.552,0-1,0.447-1,1v23H14c-0.552,0-1,0.447-1,1s0.448,1,1,1h16c0.552,0,1-0.447,1-1V7C31,6.447,30.552,6,30,6z"
                                                                                                                        ></path>
                                                                                                                    </g>
                                                                                                                </svg>
                                                                                                            </figure>
                                                                                                        </div>
                                                                                                        <div class="datetimepicker-selection-hour">00:00</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="timepicker">
                                                                                            <div class="timepicker-start">
                                                                                                <div class="timepicker-hours">
                                                                                                    <span class="timepicker-next">+</span>
                                                                                                    <div class="timepicker-input">
                                                                                                        <input type="number" />
                                                                                                        <div class="timepicker-input-number">00</div>
                                                                                                    </div>
                                                                                                    <span class="timepicker-previous">-</span>
                                                                                                </div>
                                                                                                <div class="timepicker-time-divider">:</div>
                                                                                                <div class="timepicker-minutes">
                                                                                                    <span class="timepicker-next">+</span>
                                                                                                    <div class="timepicker-input">
                                                                                                        <input type="number" />
                                                                                                        <div class="timepicker-input-number">00</div>
                                                                                                    </div>
                                                                                                    <span class="timepicker-previous">-</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="datetimepicker-footer is-hidden">
                                                                                        <button type="button" class="datetimepicker-footer-validate has-text-success button is-small is-text">
                                                                                            Validate
                                                                                        </button>
                                                                                        <button type="button" class="datetimepicker-footer-today has-text-warning button is-small is-text is-hidden">
                                                                                            Today
                                                                                        </button>
                                                                                        <button type="button" class="datetimepicker-footer-clear has-text-danger button is-small is-text">Clear</button>
                                                                                        <button type="button" class="datetimepicker-footer-cancel button is-small is-text">Cancel</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="cards__col-6 mt-15">
                                                                <div class="buttons-group">
                                                                    <DatePicker></DatePicker>
                                                                    <div class="categories time-piker dropdown-wrapper">
                                                                        <div id="datetimePicker30c0fe5c-670c-4aff-9fae-87c6e9023df1">
                                                                            <div class="datetimepicker-dummy is-primary">
                                                                                <div class="datetimepicker-dummy-wrapper">
                                                                                    <input placeholder="" readonly="readonly" class="datetimepicker-dummy-input" type="text" />

                                                                                    <input type="text" class="time is-hidden" value="00:00" />
                                                                                </div>
                                                                                <button class="datetimepicker-clear-button" type="button">＋</button>
                                                                            </div>
                                                                            <div class="datetimepicker-wrapper">
                                                                                <div class="modal-background is-hidden"></div>
                                                                                <div class="datetimepicker is-primary is-datetimepicker-default" style="position: absolute">
                                                                                    <div class="datetimepicker-container">
                                                                                        <div class="datetimepicker-header is-hidden">
                                                                                            <div class="datetimepicker-selection-details">
                                                                                                <div class="datetimepicker-selection-from is-hidden"></div>
                                                                                                <div class="datetimepicker-selection-start is-centered">
                                                                                                    <div class="datetimepicker-selection-wrapper">
                                                                                                        <div class="datetimepicker-selection-day">--</div>
                                                                                                        <div class="datetimepicker-selection-date">
                                                                                                            <div class="datetimepicker-selection-month"></div>
                                                                                                            <div class="datetimepicker-selection-weekday is-hidden"></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="datetimepicker-selection-time">
                                                                                                        <div class="datetimepicker-selection-time-icon">
                                                                                                            <figure class="image is-16x16">
                                                                                                                <svg version="1.1" x="0px" y="0px" viewBox="0 0 60 60" xml:space="preserve">
                                                                                                                    <g>
                                                                                                                        <path
                                                                                                                            fill="currentcolor"
                                                                                                                            d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z"
                                                                                                                        ></path>
                                                                                                                        <path
                                                                                                                            fill="currentcolor"
                                                                                                                            d="M30,6c-0.552,0-1,0.447-1,1v23H14c-0.552,0-1,0.447-1,1s0.448,1,1,1h16c0.552,0,1-0.447,1-1V7C31,6.447,30.552,6,30,6z"
                                                                                                                        ></path>
                                                                                                                    </g>
                                                                                                                </svg>
                                                                                                            </figure>
                                                                                                        </div>
                                                                                                        <div class="datetimepicker-selection-hour">00:00</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="timepicker">
                                                                                            <div class="timepicker-start">
                                                                                                <div class="timepicker-hours">
                                                                                                    <span class="timepicker-next">+</span>
                                                                                                    <div class="timepicker-input">
                                                                                                        <input type="number" />
                                                                                                        <div class="timepicker-input-number">00</div>
                                                                                                    </div>
                                                                                                    <span class="timepicker-previous">-</span>
                                                                                                </div>
                                                                                                <div class="timepicker-time-divider">:</div>
                                                                                                <div class="timepicker-minutes">
                                                                                                    <span class="timepicker-next">+</span>
                                                                                                    <div class="timepicker-input">
                                                                                                        <input type="number" />
                                                                                                        <div class="timepicker-input-number">00</div>
                                                                                                    </div>
                                                                                                    <span class="timepicker-previous">-</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="datetimepicker-footer is-hidden">
                                                                                        <button type="button" class="datetimepicker-footer-validate has-text-success button is-small is-text">
                                                                                            Validate
                                                                                        </button>
                                                                                        <button type="button" class="datetimepicker-footer-today has-text-warning button is-small is-text is-hidden">
                                                                                            Today
                                                                                        </button>
                                                                                        <button type="button" class="datetimepicker-footer-clear has-text-danger button is-small is-text">Clear</button>
                                                                                        <button type="button" class="datetimepicker-footer-cancel button is-small is-text">Cancel</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <label class="module__check mt-15">
                                                            <input type="checkbox" name="privacy_policy" checked="" />
                                                            <span class="check"></span>
                                                            <span class="text">Exact value of the temperature during a time range</span>
                                                        </label>
                                                        <div class="cards flex" style="max-width: 630px">
                                                            <div class="cards__col-6 mt-15">
                                                                <div class="buttons-group">
                                                                    <DatePicker></DatePicker>
                                                                    <div class="categories time-piker dropdown-wrapper">
                                                                        <div id="datetimePickere0c8974a-8a59-4493-a585-486e56846fcf">
                                                                            <div class="datetimepicker-dummy is-primary">
                                                                                <div class="datetimepicker-dummy-wrapper">
                                                                                    <input placeholder="" readonly="readonly" class="datetimepicker-dummy-input" type="text" />

                                                                                    <input type="text" class="time is-hidden" value="00:00" />
                                                                                </div>
                                                                                <button class="datetimepicker-clear-button" type="button">＋</button>
                                                                            </div>
                                                                            <div class="datetimepicker-wrapper">
                                                                                <div class="modal-background is-hidden"></div>
                                                                                <div class="datetimepicker is-primary is-datetimepicker-default" style="position: absolute">
                                                                                    <div class="datetimepicker-container">
                                                                                        <div class="datetimepicker-header is-hidden">
                                                                                            <div class="datetimepicker-selection-details">
                                                                                                <div class="datetimepicker-selection-from is-hidden"></div>
                                                                                                <div class="datetimepicker-selection-start is-centered">
                                                                                                    <div class="datetimepicker-selection-wrapper">
                                                                                                        <div class="datetimepicker-selection-day">--</div>
                                                                                                        <div class="datetimepicker-selection-date">
                                                                                                            <div class="datetimepicker-selection-month"></div>
                                                                                                            <div class="datetimepicker-selection-weekday is-hidden"></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="datetimepicker-selection-time">
                                                                                                        <div class="datetimepicker-selection-time-icon">
                                                                                                            <figure class="image is-16x16">
                                                                                                                <svg version="1.1" x="0px" y="0px" viewBox="0 0 60 60" xml:space="preserve">
                                                                                                                    <g>
                                                                                                                        <path
                                                                                                                            fill="currentcolor"
                                                                                                                            d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z"
                                                                                                                        ></path>
                                                                                                                        <path
                                                                                                                            fill="currentcolor"
                                                                                                                            d="M30,6c-0.552,0-1,0.447-1,1v23H14c-0.552,0-1,0.447-1,1s0.448,1,1,1h16c0.552,0,1-0.447,1-1V7C31,6.447,30.552,6,30,6z"
                                                                                                                        ></path>
                                                                                                                    </g>
                                                                                                                </svg>
                                                                                                            </figure>
                                                                                                        </div>
                                                                                                        <div class="datetimepicker-selection-hour">00:00</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="timepicker">
                                                                                            <div class="timepicker-start">
                                                                                                <div class="timepicker-hours">
                                                                                                    <span class="timepicker-next">+</span>
                                                                                                    <div class="timepicker-input">
                                                                                                        <input type="number" />
                                                                                                        <div class="timepicker-input-number">00</div>
                                                                                                    </div>
                                                                                                    <span class="timepicker-previous">-</span>
                                                                                                </div>
                                                                                                <div class="timepicker-time-divider">:</div>
                                                                                                <div class="timepicker-minutes">
                                                                                                    <span class="timepicker-next">+</span>
                                                                                                    <div class="timepicker-input">
                                                                                                        <input type="number" />
                                                                                                        <div class="timepicker-input-number">00</div>
                                                                                                    </div>
                                                                                                    <span class="timepicker-previous">-</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="datetimepicker-footer is-hidden">
                                                                                        <button type="button" class="datetimepicker-footer-validate has-text-success button is-small is-text">
                                                                                            Validate
                                                                                        </button>
                                                                                        <button type="button" class="datetimepicker-footer-today has-text-warning button is-small is-text is-hidden">
                                                                                            Today
                                                                                        </button>
                                                                                        <button type="button" class="datetimepicker-footer-clear has-text-danger button is-small is-text">Clear</button>
                                                                                        <button type="button" class="datetimepicker-footer-cancel button is-small is-text">Cancel</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="cards__col-6 mt-15">
                                                                <div class="buttons-group">
                                                                    <DatePicker></DatePicker>
                                                                    <div class="categories time-piker dropdown-wrapper">
                                                                        <div id="datetimePicker06f192b8-a745-48a4-807d-58a0ef51b8ba">
                                                                            <div class="datetimepicker-dummy is-primary">
                                                                                <div class="datetimepicker-dummy-wrapper">
                                                                                    <input placeholder="" readonly="readonly" class="datetimepicker-dummy-input" type="text" />

                                                                                    <input type="text" class="time is-hidden" value="00:00" />
                                                                                </div>
                                                                                <button class="datetimepicker-clear-button" type="button">＋</button>
                                                                            </div>
                                                                            <div class="datetimepicker-wrapper">
                                                                                <div class="modal-background is-hidden"></div>
                                                                                <div class="datetimepicker is-primary is-datetimepicker-default" style="position: absolute">
                                                                                    <div class="datetimepicker-container">
                                                                                        <div class="datetimepicker-header is-hidden">
                                                                                            <div class="datetimepicker-selection-details">
                                                                                                <div class="datetimepicker-selection-from is-hidden"></div>
                                                                                                <div class="datetimepicker-selection-start is-centered">
                                                                                                    <div class="datetimepicker-selection-wrapper">
                                                                                                        <div class="datetimepicker-selection-day">--</div>
                                                                                                        <div class="datetimepicker-selection-date">
                                                                                                            <div class="datetimepicker-selection-month"></div>
                                                                                                            <div class="datetimepicker-selection-weekday is-hidden"></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="datetimepicker-selection-time">
                                                                                                        <div class="datetimepicker-selection-time-icon">
                                                                                                            <figure class="image is-16x16">
                                                                                                                <svg version="1.1" x="0px" y="0px" viewBox="0 0 60 60" xml:space="preserve">
                                                                                                                    <g>
                                                                                                                        <path
                                                                                                                            fill="currentcolor"
                                                                                                                            d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z"
                                                                                                                        ></path>
                                                                                                                        <path
                                                                                                                            fill="currentcolor"
                                                                                                                            d="M30,6c-0.552,0-1,0.447-1,1v23H14c-0.552,0-1,0.447-1,1s0.448,1,1,1h16c0.552,0,1-0.447,1-1V7C31,6.447,30.552,6,30,6z"
                                                                                                                        ></path>
                                                                                                                    </g>
                                                                                                                </svg>
                                                                                                            </figure>
                                                                                                        </div>
                                                                                                        <div class="datetimepicker-selection-hour">00:00</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="timepicker">
                                                                                            <div class="timepicker-start">
                                                                                                <div class="timepicker-hours">
                                                                                                    <span class="timepicker-next">+</span>
                                                                                                    <div class="timepicker-input">
                                                                                                        <input type="number" />
                                                                                                        <div class="timepicker-input-number">00</div>
                                                                                                    </div>
                                                                                                    <span class="timepicker-previous">-</span>
                                                                                                </div>
                                                                                                <div class="timepicker-time-divider">:</div>
                                                                                                <div class="timepicker-minutes">
                                                                                                    <span class="timepicker-next">+</span>
                                                                                                    <div class="timepicker-input">
                                                                                                        <input type="number" />
                                                                                                        <div class="timepicker-input-number">00</div>
                                                                                                    </div>
                                                                                                    <span class="timepicker-previous">-</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="datetimepicker-footer is-hidden">
                                                                                        <button type="button" class="datetimepicker-footer-validate has-text-success button is-small is-text">
                                                                                            Validate
                                                                                        </button>
                                                                                        <button type="button" class="datetimepicker-footer-today has-text-warning button is-small is-text is-hidden">
                                                                                            Today
                                                                                        </button>
                                                                                        <button type="button" class="datetimepicker-footer-clear has-text-danger button is-small is-text">Clear</button>
                                                                                        <button type="button" class="datetimepicker-footer-cancel button is-small is-text">Cancel</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <button type="button" class="btn btn-w-shadow btn-standart-2">
                                        <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                                        <span>Add Devices</span>
                                    </button>
                                </div>
                            </div>
                            <div class="cards items-group border-top mt-15">
                                <div class="cards__col">
                                    <div class="headline headline--sm mt-15">
                                        <h2 class="headline__title">Product Chain Data</h2>
                                        <p class="headline__text">Select info that you want to show in QR Code</p>
                                    </div>
                                    <div class="cards flex">
                                        <div class="cards__col-4 flex flex-col">
                                            <label class="module__check">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">Date / Time of creation</span>
                                            </label>
                                        </div>
                                        <div class="cards__col-4 flex flex-col">
                                            <label class="module__check">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">Smart rules / business conditions</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="cards flex">
                                        <div class="cards__col-4 flex flex-col mt-15">
                                            <label class="module__check">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">Date / Time of dispatch dispatch</span>
                                            </label>
                                        </div>
                                        <div class="cards__col-4 flex flex-col mt-15">
                                            <label class="module__check">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">Date / Time of dispatch receive</span>
                                            </label>
                                        </div>
                                        <div class="cards__col-4 flex flex-col mt-15">
                                            <label class="module__check">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">Date / Time of dispatch return</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="cards flex">
                                        <div class="cards__col-4 flex flex-col">
                                            <label class="module__check mt-15">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">Transaction history of the product</span>
                                            </label>
                                        </div>
                                        <div class="cards__col-4 flex flex-col">
                                            <label class="module__check mt-15">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">Device rule log</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="cards flex">
                                        <div class="cards__col-4 flex flex-col mt-15">
                                            <label class="module__check">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">Basic info of the product</span>
                                            </label>
                                        </div>
                                        <div class="cards__col-4 flex flex-col mt-15">
                                            <label class="module__check">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">Parent product(s)</span>
                                            </label>
                                        </div>
                                        <div class="cards__col-4 flex flex-col mt-15">
                                            <label class="module__check">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">List of dispatches</span>
                                            </label>
                                        </div>
                                        <div class="cards__col-4 flex flex-col mt-15">
                                            <label class="module__check">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">Linked IOT devices</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
         <FooterTabsControl
            cancelRoute="dispatch"
            confirmName="Save"
            @confirm="confirm"
            @swipeTab="swipeTab"
            :pageTabs="pageTabs"
            :activeTab="activeTab"
        >
        </FooterTabsControl>
    </div>
</template>

<script>
import Base from "@/components/base";
import { mixTabs } from '@/mixins';
export default {
    name: "DispatchEditQR",
    components: {
        ...Base,
    },
    mixins: [mixTabs],
    data() {
        return {
            pageTabs:['info', 'companies', 'products', 'conditions', 'code-settings']
        };
    },
    methods: {
        openOptions(ev) {
            ev.target.classList.toggle("active");
            ev.target.nextElementSibling.classList.toggle("active");
        },
        confirm(){
            console.log('confirm')
        }
    },
};
</script>
